/* This stylesheet generated by Transfonter (https://transfonter.org) on May 8, 2017 4:17 PM */

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-BoldItalic.eot');
	src: url('../fonts/Lato/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-BoldItalic.woff') format('woff'),
		url('../fonts/Lato/Lato-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-BlackItalic.eot');
	src: url('../fonts/Lato/Lato-BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-BlackItalic.woff') format('woff'),
		url('../fonts/Lato/Lato-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-LightItalic.eot');
	src: url('../fonts/Lato/Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-LightItalic.woff') format('woff'),
		url('../fonts/Lato/Lato-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-Italic.eot');
	src: url('../fonts/Lato/Lato-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-Italic.woff') format('woff'),
		url('../fonts/Lato/Lato-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-Black.eot');
	src: url('../fonts/Lato/Lato-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-Black.woff') format('woff'),
		url('../fonts/Lato/Lato-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-Regular.eot');
	src: url('../fonts/Lato/Lato-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-Regular.woff') format('woff'),
		url('../fonts/Lato/Lato-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-Bold.eot');
	src: url('../fonts/Lato/Lato-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-Bold.woff') format('woff'),
		url('../fonts/Lato/Lato-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato/Lato-Light.eot');
	src: url('../fonts/Lato/Lato-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-Light.woff') format('woff'),
		url('../fonts/Lato/Lato-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Lato Hairline';
	src: url('../fonts/Lato/Lato-HairlineItalic.eot');
	src: url('../fonts/Lato/Lato-HairlineItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-HairlineItalic.woff') format('woff'),
		url('../fonts/Lato/Lato-HairlineItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Lato Hairline';
	src: url('../fonts/Lato/Lato-Hairline.eot');
	src: url('../fonts/Lato/Lato-Hairline.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Lato/Lato-Hairline.woff') format('woff'),
		url('../fonts/Lato/Lato-Hairline.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
