.dual-pie-view


  .item
    stroke none 
    
  .legend-item
    text
      alignment-baseline middle
    .value
      font-weight bold
    .label
      font-size 12px
  //to use with default selectionBehavior classes
  // .item
  //   stroke: #f00;
  //   stroke-width: 5;
  //   &.not-selected
  //     opacity .5
  //   &.highlighted
  //     fill: #58d !important
  //   &.selected
  //     fill #3f8 !important