.home-container
    bottom: 0
    left: 0
    position: absolute
    right: 0
    top: 40px

    .home-wrapper
        height: 100%

        #header
            align-items: center
            background-color: rgba(255,255,255,0.75)
            display: flex
            height: 40px
            position: absolute
            width: 100%
            z-index: 2

            #home-menu
                border-left: 2px solid rgba(33, 60, 77, 0.2)

        #home-content
            //height 800px
            //PENSAR BIEN ESTO!!!
            // flex 1 1 75%
            height: 100%
            position: relative

    .logo
        background-image: url("../assets/icons/Atlas-logo.svg")
        background-position: center center
        background-repeat: no-repeat
        height: 20px
        margin: 0 10px 0 20px
        width: 100px
        z-index: 3

    .buttons-wrapper
        m-font-size(0)
        margin-left: auto
        margin-right: 10px
        z-index: 3
        > button
            &:not(:last-child)
                margin-right: 20px

        .round-button
            border-radius: 50%
            display: inline-block
            height: 28px
            padding: 0
            vertical-align: middle
            width: @height
            z-index: 3
            &.hidden
                display: none
            &.download-button
                background-image url("../assets/icons/OD_Download.svg")
            &.share-button
                background-color: $blue-dianne
                background-image url("../assets/icons/OD_Share.svg")

        .subscribe-button
            &.hidden
                display: none

        .desc-button
            m-font-size(18, 18)
