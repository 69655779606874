.o-media
    display: flex
    align-items: flex-start
    // margin-bottom: 1em

.o-media__item
    margin-right: 1em
    // Remove in production
    > img
        background-color: #ccc
        height: 100px
        width: 100px

.o-media-body
    flex: 1

.o-media-body,
.o-media-body :last-child
    margin-bottom: 0

.o-media--center
    align-items: center

.o-media--reverse
    .o-media__item
        order: 1
        margin: 0 0 0 1em
