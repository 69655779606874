$buttons =  "primary" $primary-btn-color $secondary-btn-color,
            "secondary" $secondary-btn-color $primary-btn-color

button,
.button,
.btn
    border: 1px solid transparent
    border-radius: 20px
    cursor: pointer
    display: inline-block
    font-family: $base-font-family
    font-weight: $font-weight-light
    m-font-size(10, 12)
    m-letter-spacing(20)
    padding: 5px 10px
    text-align: center
    text-transform: uppercase
    vertical-align: middle
    font-weight: $font-weight-bold
    &:active
        outline: none
    &.is-disabled
        cursor: default
        opacity: 0.5

a
    &.btn
        text-decoration: none
        &:hover
            text-decoration: none

for $b in $buttons
    .btn--{$b[0]}
        background-color: $b[1]
        border-color: $b[1]
        color: $b[2]
        &:hover
            background-color: darken($b[1], 5%)
        &:active
            background-color: darken($b[1], 10%)
        &.btn--ghost
            background-color: transparent
            color: $b[1]
            &:hover
                background-color: $b[1]
                color: $b[2]
            &:active
                background-color: darken($b[1], 10%)
                color: $b[2]
