$footer-height = 40px
$header-height = 40px

body
  overflow hidden
#disclaimer
  display: none
  background: $blue-dianne
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  font-weight 100
  .content
    color #fff
    position absolute
    left  50%
    top 50%
    transform translate(-50%,-50%)
    .icon
      background-image: url(../assets/icons/devices.svg);
      width 60px
      height 60px
      background-repeat no-repeat
      background-position center
      margin 20px auto 40px auto

    .logo
      background-image: url(../assets/icons/Atlas-logo-white.svg);
      width: 160px;
      height: 80px;
      background-position: center;
      background-repeat: no-repeat;
      margin 160px auto 20px auto
    .first
      margin: 20px auto;
    .second
      margin: 20px auto;
  .mobile-text,.portrait-text
    display none


#wrapper
  display: flex
  min-height: 100vh
  flex-direction: column

  #content
    flex: 1 1 auto
    //position relative


  // #header
  //   height: $header-height

  // #footer
  //   height: $footer-height
@media screen and (orientation:portrait)
  #disclaimer
    display: block
    .portrait-text
      display block
    .mobile-text
      display none

@media screen and (max-width: 760px)
  #disclaimer
    display: block
    .mobile-text
      display block
    .portrait-text
      display none
