.horizontal-bars-view

  //to use with default selectionBehavior classes
  .item
    stroke none !important
    text
      font-size 11px
      font-weight 400
      cursor pointer
    line
      stroke #ddd
    &.not-selected
      opacity .3
      
    &.selected
      //fill #55d !important
      text
        font-weight bold
        fill #444
    
  .axis-x
    display none
  .axis-y
    display none
    // .tick
    //   text
    //     font-weight 400
    //     fill #ddd
    //   &.selected
    //     text
    //       fill #444
    //       // font-weight bold
    // .y-line
    //   line,path
    //     display none !important

  @media screen and (min-width: 1000px)
    g.tick
      font-size: 11px
