.evolutive-view
    display: flex
    flex-direction: column
    height: 100%
    overflow: hidden
    position: absolute
    width: 100%

    .top
        display: none
        flex: 0

    .middle
        display: flex
        flex: 1 1 auto
        flex-direction: row

        .left
            display: none
            flex: 0

        .center
            flex: 1 1 auto
            position: relative
            width: 100%

            .chart-region
                height: 100%
                width: 100%

                .chart
                    height: 100%
                    width: 100%

            .status
                background-color: #f8fafb //rgba(255,255,255,0.5)
                height: 100%
                left: 0
                position: absolute
                top: 0
                transition: opacity .4s ease-in-out
                width: 100%
                &.hidden
                    opacity: 0
                    pointer-events: none
                &.no-data
                    background-color: #f8fafb

                .text
                    color: $blue-dianne
                    left: 50%
                    position: absolute
                    text-align: center
                    top: 50%
                    transform: translate(-50%, -50%)

                    .oops
                        font-weight: bold
                        display: inherit

                    .sub
                        display: inherit

        .right
            display: none
            flex 0

    .bottom
        display: none
        flex 0

    .top,
    .left,
    .bottom,
    .right
        background-color: #ddd

    //to use with default selectionBehavior classes
    .item
        fill: none !important
        &.not-selected
            opacity: .3
        // &.highlighted
        //   stroke: #ff0
        &.selected
            opacity: 1
            //stroke: #55d !important
            //.breakpoint
            // fill: #55d !important

        .line
            stroke-width: 1px
        .ghost-line
            stroke-width: 12px
            opacity 0
            cursor pointer
            

        .breakpoint
            stroke: none
        .ghost-breakpoint
            opacity 0
            cursor pointer

    .grid
        stroke: #ddd !important

        line,
        path
            stroke: #ddd !important

        .domain
            display: none

    .axis
        text
            font-size: 10px

        .domain
            display: none

        .x-line
            text
                font-size: 8px
                font-weight: bold
                text-transform: uppercase

            line
                display: none
        .y-line
            line,
            path
                stroke: none !important

    .zebra
        opacity: 0.1
