.modal
    display: none
    font-family: "Lato"
    font-weight: 200
    height: 75%
    left: 50%
    position: absolute
    top: 50%
    transform: translate(-50%, -50%)
    width: @height
    z-index: 99999
    max-width: 800px;
    max-height: 600px;
    font-size: 18px;
    line-height: 24px;

    .modal-window
        align-items: center
        background-color: $blue-dianne
        //propuesta de ellos... 
        //--------------
        opacity: 0.93;
        //--------------
        color: #fff
        display: flex
        flex-direction: column
        height: 100%
        padding: 40px
        position: absolute
        width: @height

    .modal-header
        .close
            background-image: url("../assets/icons/OD_menu-open.svg")
            cursor: pointer
            height: 40px
            position: absolute
            right: 5px
            top: 5px
            width: @height

    .modal-content
        flex: 1 1 auto
        margin: 20px 0
        max-height: 100%
        overflow-y: auto
        overflow-x hidden
        // Basuar IE11
        //overflow hidden
        width 100%

        .title
            font-weight: 300
            m-font-size(32, 32)
            margin-bottom: 40px
            text-align: center
            sup
                font-size: 0.5em

        // .sub-title

        // .desc-content

    // .modal-footer
