.cookies-policy-container
    background-color: $blue-dianne
    color: #fff
    font-weight: 300
    left: 0
    m-font-size(10, 12)
    min-height: 40px
    padding-bottom: 4px
    padding-left 50px
    padding-right 50px
    padding-top: 4px
    position: fixed
    text-align: center
    top: 0
    width: 100%
    z-index: 10

    .cookie-text
        text-align:center
        max-width: 1200px
        margin: auto

        p
            margin: 0

        a
            color: #fff
            cursor: pointer
            font-weight: bold

    .close
        background-image: url("../assets/icons/OD_menu-open.svg")
        cursor: pointer
        height: 40px
        position: absolute
        right: 0
        top: 0
        width: @height
