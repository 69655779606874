.indicators-menu-wrapper
    // position: absolute
    // display inline-block
    // font-size 1.4rem
    // font-weight 400
    // left 165px
    // top: 10px
    user-select: none

    .indicators-menu-button
        // margin-top: -2px
        // width: 300px
        //Basurilla
        align-items: center
        cursor: pointer
        display: flex
        font-weight: 200
        height: 40px
        margin-left: 10px
        padding: 8px
        position: relative
        &.open
            .indicators-menu-button-icon
                m-chevron("up", 10, 2, #000)


    .indicators-menu-button-icon
        height: 15px
        m-chevron("down", 10, 2, #000)
        margin-right: 15px
        position: relative
        transition: all 0.25s ease-in-out
        width: @height

    .indicators-menu-button-text
        m-font-size(20, 24)

    .indicators-menu
        // left: -17px
        // top: 30px
        //box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.26)
        background-color: rgba(#fff, 0.9)
        height: 140px
        margin-top: 4px
        opacity: 0.9
        opacity: 1
        padding: 5px 10px 5px 10px
        position: absolute
        transition: opacity 0.25s ease-in-out
        width: 490px
        z-index: 3
        &:before
            left: 15px
            m-triangle("up", #fff, 10)
            top: -10px
        &.hidden
            opacity: 0
            z-index: -1
            pointer-events: none;
        .family-name
            font-size 10px
            text-transform uppercase
            margin-left: 10px
            border-bottom solid 1px #ddd
            margin-bottom: 0.5rem

        .indicator-button
            font-size 12px
            margin-left: -10px
            padding-left: 20px
            margin-right: -10px
            cursor: pointer
            &.selected
                font-weight: bold

    .indicator-family
        width: 150px
        display: inline-block
        vertical-align: top

    @media screen and (max-width: 930px)
        .indicator-family
            display: inline
            width: 160px

        .indicators-menu
            width: 160px
            height: 280px
