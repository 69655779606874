.zoom-wrapper
  position absolute
  bottom 245px
  left 30px
  //z-index 99999
  .zoom-button
    width: 20px;
    height: 20px;
    background: #000;
    color: #fff;
    border-radius: 10px;
    &.plus
      background-image url('../assets/icons/OD_Zoom-in.svg')
    &.minus
      background-image url('../assets/icons/OD_Zoom-out.svg')
  svg
    width 160px
    
    line
      stroke: #000
    .node-level
      cursor pointer
      rect
        fill-opacity 0
      text
        font-size 8px
        stroke: none;
        fill #888
        alignment-baseline middle
        transition all .5s
        user-select none
      &.available 
        text
          fill #000
          font-size 12px
      &.selected
        text
          fill #000
          font-weight bold
          font-size 12px
          
      