.qtip
    // FOR DEBBUGING
    // display: block !important
    // position: absolute !important
    // top: 50% !important
    // left: 50% !important
    // transform: translate(-50%, -50%) !important
    // z-index: 1000 !important

    background-color: rgba(0,0,0,0.8)
    border-color: rgba(0,0,0,1)
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.2)
    color: #fff
    padding: 20px

    .qtip-content
        font-weight: 100
        m-font-size(14, 18)
        // max-width 220px
        padding: 0
        text-align: center



    pointer-events: none


    //other default styles

    //& some-qtip-class
    // add class specific styles
