.info-panel
    background-color: rgba(255,255,255,1)
    box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.26)
    display: flex
    flex-direction: column
    font-weight: 100
    max-height: calc(100% - 100px)
    position: absolute
    right: 30px
    top: 60px
    width: 300px
    +m-breakpoint(xlg)
        width: 400px

    .panel-header
        border-top: 5px solid #213C4D
        // flex: 1
        background #fff
        padding: 10px

        .node-title
            .node-label
                font-size: 24px
                margin-bottom: 0px
            .node-type
                font-size 14px
                opacity 0.6
                line-height: 15px;
                white-space nowrap
                margin-top: 4px;
                margin-left: 2px;
        .unit-toggle
            // DE MOMENTO:
            display: none
            font-size: 10px
            margin 20px
            position: absolute
            right: 0px
            top: 0px

            .toggle
                background-color: #dd0
                height: 5px
                width: 30px

            span,
            div
                display inline-block

        .filters-bar
            display: flex

            .indicator-filter
                flex: 1 0 0
                max-width: 50%
                padding: 5px
                &.disabled
                    display: none
                    opacity: 0
                    pointer-events: none

                .filter-name
                    font-size: 12px

                .filter-select
                    width: 100%

    .panel-content-wrapper
        flex: 1 1 auto
        overflow: auto
        position: relative

        .panel-content
            background-color: #F8FAFB
            overflow: auto
            padding: 10px 20px 5px 20px

            .ind-label
                border-bottom: 1px solid #213C4D
                font-size: 20px
                margin-bottom: 1rem
                padding-bottom: 4px

            .title
                color: $blue-dianne
                font-size: 12px
                font-weight: bold
                margin: 10px 0
                position relative
                z-index: 1
                .extra-data
                    font-weight: 400;
                    display: inline-block;
                    margin-left: 5px;
                    font-size: 9px;
                .more-info
                    background-image: url("../assets/icons/OD_i.svg")
                    height: 15px
                    position: absolute
                    right: 0
                    top: 5px
                    width: 15px

            .main-chart
                // margin-top: -0.8rem
                // max-width 340px
                // was not working well with vertical bars...
                height: 189px //210px
                position: relative
                user-select: none

            .evolutive-chart
                // max-width 340px
                // height: 180px
                min-height: 160px;
                position: relative
                user-select: none

                .item
                    fill: none
                    stroke-width: 2
                    stroke: #ddd

        svg
            text
                font-family: "Lato"

        .no-data-cover,.loading-cover
            background-color: #fff
            height: 100%
            left: 0
            position: absolute
            top: 0
            width: 100%
            z-index: 2
            &.hidden
                display: none

            .text
                font-siz:e 20px
                left: 50%
                position: absolute
                top: 50%
                transform: translate(-50%,-50%)

// @media screen and (max-width: 950px)
//     .info-panel
//       width 95%

// @media screen and (max-width: 825px)
//     .info-panel
//       width 90%

// @media screen and (max-width: 700px)
//     .info-panel
//       width 85%

// @media screen and (max-width: 650px)
//     .info-panel
//       width 80%

// @media screen and (max-width: 600px)
//     .info-panel
//       width 80%


//replaced by .panel-content: max-height 75vh
// @media screen and (max-height: 760px)
//     .panel-content
//       max-height: 31.5rem

// @media screen and (max-height: 740px)
//     .panel-content
//       max-height: 30rem

// @media screen and (max-height: 720px)
//     .panel-content
//       max-height: 28.5rem

// @media screen and (max-height: 695px)
//     .panel-content
//       max-height: 27rem


// @media screen and (max-height: 670px)
//     .panel-content
//       max-height: 25.5rem

// @media a screen and (max-height: 645px)
//     .panel-content
//       max-height: 24rem

// @media screen and (max-height: 625px)
//     .panel-content
//       max-height: 22rem
