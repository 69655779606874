*,
*:before,
*:after
  box-sizing: inherit

html
    box-sizing: border-box
    font-size: unit($base-font-size, px)
    line-height: unit($base-font-size * 1.5, px)

body
    color: $blue-dianne
    font-family: $base-font-family
    letter-spacing: 0.02em
    margin: 0
    padding: 0

ul,
li
    list-style-type: none
    margin: 0
    padding: 0

img
    border: none
    max-width: 100%
