.vertical-ranged-bars-view

  //to use with default selectionBehavior classes
  .item
    &.not-selected
      opacity .3
    &.highlighted
      fill: #58d !important
      stroke $blue-dianne
      stroke-opacity 0.8
    &.selected
      fill #3f8 !important
      stroke $blue-dianne
      

  .axis-y
    .y-line
      line,path
        display none !important
        
  .axis-x
    .tick
      text
        //font-weight 400
        fill #444
      line
        stroke #ddd !important
        
    .domain
      display none !important
    // .x-line
    //   line //,path
    //     display none !important
  .y-grid
    line
      stroke #ddd !important
    .domain
      display none