
.filter-button
  border solid 1px #888
  display inline-block
  width 100%
  display flex
  align-items: center;
  .label
    flex 1 0 0
    font-size 12px
    display inline-block
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  .icon

    width 15px
    height 15px
    background-size contain
    background-repeat no-repeat
    display inline-block
    vertical-align: middle;
    margin 5px
    //Warning: este -2px es porque el svg no esta centrado...
    margin-bottom: -2px;

.info-tip
  //background-color #ddd
  border none
  padding: 10px

  .qtip-content
    m-font-size(12, 14)

  .qtip-tip
    background-color rgba(0,0,0,0.8)
    border-color rgba(0,0,0,0.8)

.filter-tip
  pointer-events all
  max-width: none !important
  // width 800px
  // height 600px
  background-color #fff !important
  color #000
  border none
  box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.2);
  .qtip-content
    width 500px
    font-size 12px
    line-height 14px
    text-align left
    padding: 0px;
    .filter-header
      padding: 10px 10px 0px 10px
      .filter-search
        text-align right
        position relative
        .icon
          width: 10px;
          height: 10px;
          background-image: url('../assets/icons/OD_Search.svg');
          position: absolute;
          right: 0px;
          top: 0px;
          margin: 6px;
    .all-item
      margin-top 5px
      margin-bottom 5px
    .families
      max-height 500px
      overflow auto
      .families-wrapper
        padding: 0px 10px 10px 10px;
        .filter-family
          margin-bottom 10px
          .family-name
            font-weight bold
          .items-list
            column-count: 4
            .filter-item
              width 100%
              margin 4px
              &.selected
                background-color #ddd
              b
                background-color #eee

