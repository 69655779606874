.panel-viz-view
    background-color: none
    display: flex
    flex-direction: column
    height: 100%
    overflow: hidden
    position: absolute
    width: 100%

    .top
        display: none
        flex: 0

    .middle
        display: flex
        flex-direction: row
        flex: 1 1 auto
        position: relative

        // .left
        //     display: none
        //     flex 0

        .center
            flex: 1 1 auto
            // height: 100%
            position: relative
            width: 100%

            .chart-region
                height: 100%
                left: 0
                position: absolute
                top: 0
                width: 100%

                .chart
                    height: 100%
                    width: 100%

            .status
                background-color: #f8fafb //rgba(255,255,255,0.5)
                height: 100%
                left: 0
                position: absolute
                top: 0
                transition: opacity .4s ease-in-out
                width: 100%
                &.hidden
                    opacity: 0
                    pointer-events: none
                &.no-data
                    background-color #f8fafb

                > span
                    color: $blue-dianne
                    left: 50%
                    position: absolute
                    text-align: center
                    top: 50%
                    transform: translate(-50%, -50%)

                    .oops
                        display: inherit
                        font-weight: bold

                    .sub
                        display: inherit

        // .right
        //     display: none
        //     flex 0

    .bottom
        display: none
        flex 0

    .top,
    .left,
    .bottom,
    .right
        background-color: #ddd

    //to use with default selectionBehavior classes
    .item
        &.not-selected
            opacity .5
        // &.highlighted
        //   fill: #58d !important
        // &.selected
        //   fill #3f8 !important
