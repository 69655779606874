#modal
    //font-size 16px
    font-size: 18px;
    line-height: 24px;
    .modal-content
        text-align: left
        position relative
        display flex
        flex-direction column
        .content-wrapper
            flex 1 0 auto
            position relative
            overflow hidden
            .content
                position absolute
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                display flex
                width 90%
                margin auto
                // font-size 18px
                // line-height 24px
                .left,.right
                    flex 1 1 50%
                    padding 20px
                    //.sub-title
                        //width: 80%;
                        //margin: auto;
                        
                    .learn-more
                        margin-top 40px
                        font-weight bold
                        text-decoration underline
                        label
                            cursor pointer
                    .form
                        margin-left 60px
                        //text-align center
                        input
                            background-color: $blue-dianne
                            border: 1px solid #fff
                            color: #fff
                            margin: 5px
                            outline: none
                            padding: 5px
                            width: 182px

                        select
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            border-radius: 0px
                            box-sizing: content-box;
                            background-color: $blue-dianne
                            border: 1px solid #fff
                            color: #fff
                            font-family: Lato
                            padding: 4px
                            width: 172px
                            margin: 5px

                        .form-title
                            margin-bottom 25px


                    .terms-and-conds
                        font-weight: 100
                        font-size 12px
                        margin 5px
                        &.missing
                            color: #f55
                        &.hidden
                            visibility: hidden

                        a
                            cursor pointer
                            font-weight bold
                        // label
                        //     padding-left: 15px;
                        //     text-indent: -15px;
                        input
                            width: 13px;
                            height: 13px;
                            padding: 0;
                            margin:2px
                            vertical-align: bottom;
                            position: relative;
                            top: -1px;
                            overflow: hidden;
                            
                    .must-check
                        m-font-size(10, 12)
                        font-weight: 100
