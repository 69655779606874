.search
    align-items: center
    display: flex
    height: 100%
    position: relative
    .search-input
        border: none
        border-radius: 50%
        flex: 1 0 auto
        height: 100%
        outline: none
        // padding: 15px
        margin: 15px;
        padding-left: 5px;
        line-height 10px
        &::placeholder
            color: #99ABAD

    .search-logo
        background-image: url("../assets/icons/OD_Search.svg")
        display: inline-block
        height: 15px
        margin: 10px 15px 10px 0
        width: 15px

.autocomplete-suggestions
    //border 1px solid #999

    background-color: #fff
    margin-top: 5px
    max-width: 340px
    min-width: 240px
    overflow: auto
    z-index: 1 !important


    strong
        color: $blue-dianne
        font-weight: bold

    .type
        color: #aaa
        font-style: italic

.autocomplete-suggestion,.autocomplete-no-suggestion
    //white-space nowrap
    align-items: center
    display: flex
    padding: 2px 5px

    .suggestion-content
        display: flex
        align-items: center

        .shape
            height: 40px
            width: 40px
            svg
                height: 40px
                width: 40px
                path
                    fill: #ddd
        .value
            margin: 10px
.autocomplete-no-suggestion
    color #ddd
    
.autocomplete-selected
    background-color #f0f0f0

    .shape
        svg
            path
                fill: #888 !important

.autocomplete-group
    padding 2px 5px

    strong
        border-bottom: 1px solid #000
        display: block
