.hybrid-map-view
    background-color: none
    display: flex
    flex-direction: column
    height: 100%
    overflow: hidden
    position: absolute
    width: 100%

    .top
        // display: none
        // flex: 0 0 auto
        position: relative
        z-index: 1

    .middle
        display: flex
        flex-direction: row
        flex: 1 1 auto
        position: relative

        // .left
        //     display: none
        //     flex 0 0 auto

        .center
            flex 1 1 auto
            position relative
            width: 100%

            .chart-region
                height: 100%
                left: 0
                position: absolute
                top: 0
                width: 100%

                .chart
                    height 100%
                    width 100%
                    z-index: 0

            .no-data-region
                background-color: rgba(255,255,255,0.2)
                height: 100%
                left: 0
                pointer-events: none
                position: absolute
                top: 0
                width: 100%
                &.hidden
                    display: none

                span
                    left: 50%
                    position: absolute
                    top: 50%
                    transform: trnaslate(-50%, -50%)
            .forbidden-message,.outside-message,.no-poly-message
                width 100%
                height 100%
                //background rgba(255,255,255,0.7)
                position absolute
                top 0
                left 0
                opacity 1
                transition opacity 1s
                .text
                    top: 50%
                    left: 50%
                    transform: translate(-50%, -50%)
                    text-align: center
                    position: absolute
                    font-size: 32px
                    font-weight: 200
                    margin-top: 40px
                    width: 300px;
                    .button
                        font-size 32px
                        cursor pointer
                        pointer-events all
                        font-weight bold
                &.hidden
                    opacity 0
                    .button
                        pointer-events none

            .loader
                height: 80px
                left: 50%
                opacity: 1
                visibility: visible
                position: absolute
                top: 50%
                transform: translate(-50%, -50%)
                transition: opacity 0.8s ease-in-out, visibility 0.8s
                width: @height
                &.hidden
                    visibility: hidden
                    opacity: 0

            .loader-icon,
            .loader-icon:after
                border-radius: 50%
                height: 80px
                width: @height

            .loader-icon
                animation: load8 1.1s infinite linear
                border-bottom: 10px solid rgba($blue-dianne, 0.2)
                border-left: 10px solid $blue-dianne
                border-right: 10px solid rgba($blue-dianne, 0.2)
                border-top: 10px solid rgba($blue-dianne, 0.2)
                position: relative
                transform: translateZ(0)

            @keyframes load8
                0%
                    transform: rotate(0deg)
                    transform-origin: center center

                100%
                    transform: rotate(360deg)
                    transform-origin: center center

        // .right
            // display: none
            // flex: 0
            // position absolute
            // right 30px
            // top 60px

        // ?? didnt look good...
        // @media screen and (max-width: 950px)
        //   .right
        //     right 0px

        // @media screen and (max-width: 800px)
        //   .right
        //     right -30px

    .bottom
        // flex 0 0 auto
        position relative
        z-index 1

    .info-panel-wrapper
        height: 100%
        margin-left: auto
        width: 100%

        //padding 20px

    // @media screen and (max-width: 950px)
    //   .info-panel-wrapper
    //     width 90%

    // @media screen and (max-width: 825px)
    //   .info-panel-wrapper
    //     width 85%

    // @media screen and (max-width: 700px)
    //   .info-panel-wrapper
    //     width 80%

    // @media screen and (max-width: 650px)
    //   .info-panel-wrapper
    //     width 75%

    // @media screen and (max-width: 600px)
    //   .info-panel-wrapper
    //     width 70%

    //to use with default selectionBehavior classes
    .leaflet-pane
        z-index: auto
        z-index: initial !important
        svg
            z-index 2
            defs
                path
                    stroke #aaa
                    stroke-width 1px
        .item

            fill-opacity 0.8
            stroke #666 //rgba(155,155,155,1) //#9b9b9b
            stroke-opacity 0.2
            // stroke-width 1
            // &.not-selected
            //   opacity .5
            // &.highlighted
            //   fill: #58d !important
            &.selected

                stroke $blue-dianne //rgba(39,53,56,1)
                stroke-opacity 1
                // stroke-width 1

            &.parent
                fill-opacity 0
                path
                    pointer-events none !important

    .canary-layer
        .canary-box
            background-color: rgba(#BFD6DC, 0.9)
            border: 1px solid #273538
            bottom: 80px
            height: 100px
            left: 20px
            position: absolute
            transition: opacity 1s ease-in-out
            width: 220px
            z-index: 999
            &.hidden
                opacity: 0
                pointer-events: none
            .canary-item
                fill-opacity: 0.5
                stroke-width: 1
                stroke: rgba(155,155,155,1) //#9b9b9b
                &.selected
                    stroke: rgba(39,53,56,1) //#273538

    .gradient-layer
        //background-color rgba(255,0,0,0.2)
        background: -webkit-linear-gradient(left, rgba(255,255,255,0.4) 0%,rgba(255,255,255,0) 25%,rgba(255,255,255,0) 100%)
        height: 100%
        pointer-events: none
        position: absolute
        width: 100%
        z-index: 0

    .crosshair-layer
        //for debugging
        // border: solid 1px
        height: 100%
        pointer-events: none
        position: absolute
        z-index: 999

        .icon
            height: 12px
            left: 50%
            opacity: 0.4
            position: absolute
            top: 50%
            transform: translate(-50%, -50%)
            width: @height
            &:before
                background-color: #000
                content: ""
                height: 100%
                left: 50%
                position: absolute
                top: 50%
                transform: translate(-50%, -50%)
                width: 2px
            &:after
                background-color: #000
                content: ""
                height: 2px
                left: 50%
                position: absolute
                top: 50%
                transform: translate(-50%, -50%)
                width: 100%

    .search-wrapper
        position: absolute
        width: 240px
        height: 35px
        top: 50px
        left: 20px
        background #fff
        border-radius: 15px

    .credits-wrapper
        align-items: center
        background-color: rgba(255,255,255,0)
        bottom: 0
        color: $blue-dianne
        display: flex
        padding: 3px
        position: absolute
        right: 0
        .t-y-c,.cookies
            margin-right 80px
            cursor pointer
            font-size 9px
            line-height: 8px;
            // margin-top: 8px;
        .cookies
            margin-right 120px
            
        .credits
            text-transform uppercase
            font-size 10px
            font-weight 100
            margin-left 10px
            margin-top 3px
        .bs-logo
            background-image: url('../assets/icons/innocells-dark.png')
            width: 90px
            height: 15px
            background-repeat: no-repeat
            background-size: contain
            margin-left 10px
            cursor pointer
        .desc-button
            cursor pointer
            font-size 10px
            font-weight 100
            margin-left 10px
            margin-top 3px
    .leaflet-control-attribution
        display none
.chart-tooltip
    background-color: rgba(0,0,0,0.8)
    border-color: rgba(0,0,0,1)
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.2)
    color: #fff
    padding: 20px

    .qtip-content
        font-weight: 100
        m-font-size(14, 18)
        // max-width 220px
        padding: 0
        text-align: center

.options-tooltip
    background-color #fff
    border none
    box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.26)
    color: #555
    padding: 0
    pointer-events all
    .qtip-tip
        background-color #fff
        border-color #fff
    .qtip-content
        padding 0px
    ul
        li
            font-size 10px
            // margin 10px
            padding 10px
            cursor pointer
            &:hover
                background-color: lightgrey

// .share-menu
//     background-color #fff
//     position: absolute
//     width: 160px
//     height: 80px
//     z-index: 50
//     top: 40px
//     left: -110px
//     box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.26)
//     padding: 5px 10px 5px 10px
//     visibility: hidden

// .share-option
//     font-size 10px
//     margin-left: -10px
//     padding-left: 20px
//     margin-right: -10px


// .download-menu
//     background-color #fff
//     position: absolute
//     width: 160px
//     height: 80px
//     z-index: 50
//     top: 40px
//     left: -145px
//     box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.26)
//     padding: 5px 10px 5px 10px
//     visibility: hidden

// .download-option
//     font-size 10px
//     margin-left: -10px
//     padding-left: 20px
//     margin-right: -10px

// .download-option:hover
//   background-color: lightgrey
//   cursor: pointer

// .share-option:hover
//   background-color: lightgrey
//   cursor: pointer




