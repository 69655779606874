.legend-wrapper
  position: absolute;
  bottom: 20px;
  left: 20px;
  //z-index: 9999;
  width: 100%;
  height: 50px;
  pointer-events none
  .legend-title
    width: 100%;
    font-size 14px
    font-weight bold
    margin-bottom 10px
  .legend-item
    stroke #fff
    text
      font-size 12px
      stroke none
      fill #000
  .hours-item
    stroke: #444
    stroke-width 1px
    text
      font-size 10px
      stroke none
      fill #000
  rect
    fill-opacity: 0.8